import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as RoutesContainer,
  Navigate,
} from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import store from "./store/store";
import { Provider } from "react-redux";
import Games from "./pages/Games";
import Results from "./pages/Results";
import Pools from "./pages/Pools";
import Records from "./pages/Records";
import Stats from "./pages/Stats";
import RestrictedPage from "./pages/RestrictedPage";
import Standing from "./pages/Standing";
import MainLayout from "./Layout/MainLayout";
import Teams from "./pages/Teams";
import FbChallanges from "./pages/FbChallanges";
import Admin from "./pages/Admin/FormToggle";
import { LeagueProvider } from "./components/LeagueContext";
import { ToastContainer } from "react-toastify";
import HomePage from "./pages/home/home";
import HomeCopy from "./pages/home/homecopy";
import Test from "./pages/test/test";
import { useEffect } from "react";
import { useState } from "react";
import Share from "./pages/Share";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermOfUse from "./pages/TermOfUse";
import CookiePolicy from "./pages/CookiePolicy";
import AcceptableUsePolicy from "./pages/AcceptableUsePolicy";
import Fullleaderboard from "./pages/NEW/Fullleaderboard";
import ClaimOffer from "./components/Registration/ClaimOffer/ClaimOffer";
import Wishlist from "./components/Registration/ClaimOffer/Wishlist";
import TestLogin from "./pages/home/TestLogin";
// import Wishlist from "./pages/Wishlist";
// import Season from "./pages/Season";

// New HOC for protected routes
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("_id") !== null;
  return isAuthenticated ? children : <Navigate to="/" replace />;
};

const Routes = () => {
  const queryClient = new QueryClient();
  const [isUserAdmin, setIsuserAdmin] = useState(false);
  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin");
    try {
      setIsuserAdmin(JSON.parse(isAdmin));
    } catch (error) {
      console.error("Error parsing isAdmin:", error);
    }
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <Router>
          <LeagueProvider>
            <RoutesContainer>
              {/* <Route path="/test" element={<HomeCopy />} /> */}
              <Route index element={<HomePage />} />
              <Route path="/waitlist" element={<Wishlist />} />
              <Route path="/get-offer" element={<ClaimOffer />} />

              <Route element={<MainLayout />}>
                <Route
                  path="/games"
                  element={
                    <ProtectedRoute>
                      <Games />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/results"
                  element={
                    <ProtectedRoute>
                      <Results />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/pools"
                  element={
                    <ProtectedRoute>
                      <Pools />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/record"
                  element={
                    <ProtectedRoute>
                      <Records />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/stats"
                  element={
                    <ProtectedRoute>
                      <Stats />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/testleader"
                  element={
                    <ProtectedRoute>
                      <Fullleaderboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/standings"
                  element={
                    <ProtectedRoute>
                      <Standing />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/teams"
                  element={
                    <ProtectedRoute>
                      <Teams />
                    </ProtectedRoute>
                  }
                />

                {/* <Route path="/season" element={<Season />} /> */}
                <Route
                  path="/fb-challanges"
                  element={
                    <ProtectedRoute>
                      <FbChallanges />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/records"
                  element={
                    <ProtectedRoute>
                      <Records />
                    </ProtectedRoute>
                  }
                />

                {isUserAdmin && <Route path="/admin" element={<Admin />} />}

                <Route
                  path="/test"
                  element={
                    <ProtectedRoute>
                      <Test />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/share"
                  element={
                    <ProtectedRoute>
                      <Share />
                    </ProtectedRoute>
                  }
                />
                <Route path="/testlogin" element={<TestLogin />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermOfUse />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route
                  path="/acceptable-use-policy"
                  element={<AcceptableUsePolicy />}
                />
              </Route>
            </RoutesContainer>
          </LeagueProvider>
        </Router>
      </QueryClientProvider>
    </Provider>
  );
};

export default Routes;
