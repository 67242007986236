import React, { useEffect, useState } from "react";
import "./Styles.css";
import { useLeagueContext } from "../LeagueContext";
import { getUserById } from "../../Apis/auth";
import { getGamePlayedByUserId } from "../../Apis/predictions";
import { getGamesPlayedByDateRange } from "../../Apis/predictions";
import { headerOptions } from "./data"; // Import headerOptions
import NHLStandingTables from "./NHL";
import MLBStandingTables from "./MLB";
import NFLStandingTables from "./NFL";
import NBAStandingTables from "./NBA";

const calculateReg = (row) => {
  return row.result?.endingsPoints?.pickRegulation || 0;
};

const calculateOT = (row) => {
  return row.result?.endingsPoints?.pickOverTime || 0;
};

const calculateSO = (row) => {
  return row.result?.endingsPoints?.pickShootout || 0;
};

const calculateEI = (row) => {
  return row.result?.endingsPoints?.pickExtraInnings || 0;
};

// Utility functions for calculations
const calculateWinStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  games.forEach((game) => {
    if (game.BR > 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateLossStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  games.forEach((game) => {
    if (game.BR <= 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateCurrentStreak = (games) => {
  // Log the games array to the console
  console.log("Games Array:", games);

  let streak = "";
  let wins = 0,
    losses = 0;

  for (let i = games.length - 1; i >= 0; i--) {
    if (games[i].BR > 0) {
      wins++;
      if (losses > 0) break;
    } else {
      losses++;
      if (wins > 0) break;
    }
  }
  
  if (wins > 0) streak = `W${wins}`;
  if (losses > 0) streak = `L${losses}`;
  
  return streak;
};


const calculatePointsPercentage = (games, condition) => {
  const totalPoints = games.reduce(
    (acc, game) => acc + (game[condition] || 0),
    0
  );
  const maxPoints = games.length * 100; // Assume max points per condition is 100
  return ((totalPoints / maxPoints) * 100).toFixed(2);
};

const calculateL10 = (games) => {
  const last10Games = games.slice(-10);
  let winCount = 0,
    lossCount = 0;
  last10Games.forEach((game) => {
    if (game.BR > 0) winCount++;
    else lossCount++;
  });
  return `${winCount}/${lossCount}`;
};

const calculateFavoritePointsPercentage = (games) => {
  // Placeholder logic for favorite points calculation
  const favoritePoints = games.reduce(
    (acc, game) => acc + (game.favoritePoints || 0),
    0
  );
  const maxFavoritePoints = games.length * 100; // Assume max points for favorites is 100
  return ((favoritePoints / maxFavoritePoints) * 100).toFixed(2);
};

const calculateUnderdogPointsPercentage = (games) => {
  // Placeholder logic for underdog points calculation
  const underdogPoints = games.reduce(
    (acc, game) => acc + (game.underdogPoints || 0),
    0
  );
  const maxUnderdogPoints = games.length * 100; // Assume max points for underdogs is 100
  return ((underdogPoints / maxUnderdogPoints) * 100).toFixed(2);
};

// Function to calculate user statistics
const calculateUserStats = (games) => {
  const statsMap = {};

  games.forEach((game) => {
    const userId = game.userId;

    if (!statsMap[userId]) {
      statsMap[userId] = {
        gamesPlayed: 0,
        wins: 0,
        losses: 0,
        points: 0,
      };
    }

    statsMap[userId].gamesPlayed += 1;
    statsMap[userId].points += game.BR ? parseFloat(game.BR) : 0;

    if (game.BR > 0) {
      statsMap[userId].wins += 1;
    } else {
      statsMap[userId].losses += 1;
    }
  });

  Object.keys(statsMap).forEach((userId) => {
    const userStats = statsMap[userId];
    userStats.winPercentage = (
      (userStats.wins / userStats.gamesPlayed) *
      100
    ).toFixed(2);
    userStats.avgPointsPerGame = (
      userStats.points / userStats.gamesPlayed
    ).toFixed(2);
  });

  return statsMap;
};

const StandingTables = () => {
  const { selectedLeague } = useLeagueContext();
  const [filteredHeaderOptions, setFilteredHeaderOptions] = useState([]);
  const [allPlayersData, setAllPlayersData] = useState([]);
  const [gameDataMap, setGameDataMap] = useState({});
  const [userStatsMap, setUserStatsMap] = useState({});
  const id = localStorage.getItem("_id");

  useEffect(() => {
    if (selectedLeague && headerOptions[selectedLeague]) {
      setFilteredHeaderOptions(headerOptions[selectedLeague]);
    } else {
      setFilteredHeaderOptions([]);
    }

    fetchAllPlayersData();
  }, [selectedLeague]);

  const fetchAllPlayersData = async () => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 30); // Get data for the last 30 days

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    try {
      const response = await getGamesPlayedByDateRange(
        formattedStartDate,
        formattedEndDate
      );
      if (response.data && Array.isArray(response.data.data)) {
        const filteredData = response.data.data.filter(
          (game) => game.league === selectedLeague
        );
        const enhancedData = await Promise.all(
          filteredData.map(async (game) => {
            const userData = await getUserById(game.userId);
            return {
              ...game,
              co: userData.data.country || "-",
              state: userData.data.state || "-",
              city: userData.data.city || "-",
              player: userData.data.leagues[0]?.username || "-",
              BR:
                game.result?.perfectScore != null
                  ? parseFloat(game.result?.perfectScore).toFixed(2)
                  : "-",
              vegasOdds: game.result?.vegasOdds || {},
            };
          })
        );
        setAllPlayersData(enhancedData);
        const userStats = calculateUserStats(enhancedData);
        setUserStatsMap(userStats);
      }
    } catch (error) {
      console.error("Error fetching all players data:", error);
    }
  };

  // Calculate TP points and ranks
  const calculateTPandRank = (games) => {
    const tpValues = games.map((row) => {
      return parseFloat(
        (row.result?.accuracyPoints?.home?.p1s || 0) +
          (row.result?.accuracyPoints?.vistor?.p1s || 0) +
          (row.result?.accuracyPoints?.home?.p1s2p || 0) +
          (row.result?.accuracyPoints?.vistor?.p1s2p || 0) +
          (row.result?.accuracyPoints?.home?.p2s2p || 0) +
          (row.result?.accuracyPoints?.vistor?.p2s2p || 0) +
          (row.result?.vegasOdds?.pickingFavorite ||
            row.result?.vegasOdds?.pickingUnderdog ||
            0) +
          (row.result?.vegasOdds?.pickingOver ||
            row.result?.vegasOdds?.pickingUnder ||
            0) +
          (row.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
            row.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
            0) +
          (row.BR || 0)
      ).toFixed(2);
    });

    // Rank the TP values
    const sortedGames = tpValues.sort((a, b) => b.tp - a.tp);
    const ranks = sortedGames.map((game, index) => index + 1);

    return { tpValues, ranks };
  };

  // Calculate TP points and ranks
  const { tpValues, ranks } = calculateTPandRank(allPlayersData);

  // Define a function to render the appropriate columns based on the selected league
  const renderColumns = (row, index, ranks, tpValues, gameData) => {
    const Reg = calculateReg(row);
    const OT = calculateOT(row);
    const SO = calculateSO(row);
    const EI = calculateEI(row);

    switch (selectedLeague) {
      case "NHL":
        return (
          <>
            <td className="text-xs font-medium text-center">{Reg || "0"}</td>
            <td className="text-xs font-medium text-center">{OT || "-"}</td>
            <td className="text-xs font-medium text-center">{SO || "-"}</td>
          </>
        );
      case "NBA":
      case "NFL":
        return (
          <>
            <td className="text-xs font-medium text-center">{Reg || "0"}</td>
            <td className="text-xs font-medium text-center">{OT || "-"}</td>
          </>
        );
      case "MLB":
        return (
          <>
            <td className="text-xs font-medium text-center">{Reg || "0"}</td>
            <td className="text-xs font-medium text-center">{EI || "0"}</td>
          </>
        );
      default:
        return null;
    }
  };

  const aggregatePlayerData = (data) => {
    const playerMap = {};

    data.forEach((game) => {
      if (!playerMap[game.userId]) {
        playerMap[game.userId] = {
          co: game.co,
          state: game.state,
          city: game.city,
          player: game.player,
          userId: game.userId,
          gamesPlayed: 0,
          totalBR: 0,
          uniqueNights: new Set(), // To track unique nights
          wins: 0,
          losses: 0,
          oneS: 0,
          oneS0: 0,
          oneSW2: 0,
          twoSW2: 0,
          ml: 0,
          spread: 0,
          ou: 0,
          games: [],
        };
      }

      const player = playerMap[game.userId];
      player.gamesPlayed++;
      player.totalBR += parseFloat(game.BR) || 0;
      player.uniqueNights.add(
        game.date
          ? game.date.split("T")[0]
          : `game-${game.id || player.gamesPlayed}`
      ); // Add the date (without time) to the set
      player.wins +=
        parseFloat(
          game.result?.vegasOdds?.pickingFavorite ||
            game.result?.vegasOdds?.pickingUnderdog
        ) > 0
          ? 1
          : 0;

      // Calculate losses as total games played minus wins
      player.losses = player.gamesPlayed - player.wins;

      player.oneS +=
        (game.result?.accuracyPoints?.home?.p1s || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s || 0);

      player.oneS0 +=
        (game.result?.accuracyPoints?.home?.p1s0 || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s0 || 0);

      player.oneSW2 +=
        (game.result?.accuracyPoints?.home?.p1s2p || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s2p || 0);

      player.twoSW2 +=
        (game.result?.accuracyPoints?.home?.p2s2p || 0) +
        (game.result?.accuracyPoints?.vistor?.p2s2p || 0);

      player.ml += parseFloat(
        game.result?.vegasOdds?.pickingFavorite ||
          game.result?.vegasOdds?.pickingUnderdog ||
          0
      );
      player.spread += parseFloat(
        game.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
          game.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
          0
      );
      player.ou += parseFloat(
        game.result?.vegasOdds?.pickingOver ||
          game.result?.vegasOdds?.pickingUnder ||
          0
      );
      player.games.push(game);
    });

    return Object.values(playerMap);
  };

  const aggregatedPlayersData = aggregatePlayerData(allPlayersData);

  // Calculate TP points and ranks for aggregated data
  const { tpValues: aggregatedTPValues, ranks: aggregatedRanks } =
    calculateTPandRank(aggregatedPlayersData);

  // Render the appropriate table based on the selected league
  const renderLeagueTable = () => {
    switch (selectedLeague) {
      case "NHL":
        return <NHLStandingTables />;
      case "MLB":
        return <MLBStandingTables />;
      case "NFL":
        return <NFLStandingTables />;
      case "NBA":
        return <NBAStandingTables />;
      // Add cases for other leagues here
      default:
        return (
          <div>
            No table available for the selected league: {selectedLeague}
          </div>
        );
    }
  };

  return <div className="table-container">{renderLeagueTable()}</div>;
};

export default StandingTables;
