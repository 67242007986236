import React, { useEffect, useState } from "react";
import "./Styles.css";
import { useLeagueContext } from "../LeagueContext";
import { getUserById } from "../../Apis/auth";
import { getGamesPlayedByDateRange } from "../../Apis/predictions";
import { headerOptions } from "./data";

const calculateReg = (row) => {
  return row.result?.endingsPoints?.pickRegulation || 0;
};

const calculateOT = (row) => {
  return row.result?.endingsPoints?.pickOverTime || 0;
};

const calculateSO = (row) => {
  return row.result?.endingsPoints?.pickShootout || 0;
};

const calculateEI = (row) => {
  return row.result?.endingsPoints?.pickExtraInnings || 0;
};

// Utility functions for calculations
const calculateWinStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  games.forEach((game) => {
    if (game.BR > 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateLossStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  games.forEach((game) => {
    if (game.BR <= 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateCurrentStreak = (games) => {
  let streak = "";
  let wins = 0,
    losses = 0;
  for (let i = games.length - 1; i >= 0; i--) {
    if (games[i].BR > 0) {
      wins++;
      if (losses > 0) break;
    } else {
      losses++;
      if (wins > 0) break;
    }
  }
  if (wins > 0) streak = `W${wins}`;
  if (losses > 0) streak = `L${losses}`;
  return streak;
};

const calculatePointsPercentage = (games, condition) => {
  const totalPoints = games.reduce(
    (acc, game) => acc + (game[condition] || 0),
    0
  );
  const maxPoints = games.length * 100; // Assume max points per condition is 100
  return ((totalPoints / maxPoints) * 100).toFixed(2);
};

const calculateL10 = (games) => {
  const last10Games = games.slice(-10);
  let winCount = 0,
    lossCount = 0;
  last10Games.forEach((game) => {
    if (game.BR > 0) winCount++;
    else lossCount++;
  });
  return `${winCount}/${lossCount}`;
};

const calculateFavoritePointsPercentage = (games) => {
  // Placeholder logic for favorite points calculation
  const favoritePoints = games.reduce(
    (acc, game) => acc + (game.favoritePoints || 0),
    0
  );
  const maxFavoritePoints = games.length * 100; // Assume max points for favorites is 100
  return ((favoritePoints / maxFavoritePoints) * 100).toFixed(2);
};

const calculateUnderdogPointsPercentage = (games) => {
  // Placeholder logic for underdog points calculation
  const underdogPoints = games.reduce(
    (acc, game) => acc + (game.underdogPoints || 0),
    0
  );
  const maxUnderdogPoints = games.length * 100; // Assume max points for underdogs is 100
  return ((underdogPoints / maxUnderdogPoints) * 100).toFixed(2);
};

// Function to calculate user statistics
const calculateUserStats = (games) => {
  const statsMap = {};

  games.forEach((game) => {
    const userId = game.userId;

    if (!statsMap[userId]) {
      statsMap[userId] = {
        gamesPlayed: 0,
        wins: 0,
        losses: 0,
        points: 0,
        CGS: 0, // Add CGS property to track consecutive predictions
      };
    }

    statsMap[userId].gamesPlayed += 1;
    statsMap[userId].points += game.BR ? parseFloat(game.BR) : 0;

    if (game.BR > 0) {
      statsMap[userId].wins += 1;
    } else {
      statsMap[userId].losses += 1;
    }

    // Increment CGS if the user made a prediction
    if (game.result?.predictionMade) {
      statsMap[userId].CGS += 1;
    } else {
      statsMap[userId].CGS = 0; // Reset CGS if the user didn't make a prediction
    }
  });

  Object.keys(statsMap).forEach((userId) => {
    const userStats = statsMap[userId];
    userStats.winPercentage = (
      (userStats.wins / userStats.gamesPlayed) *
      100
    ).toFixed(2);
    userStats.avgPointsPerGame = (
      userStats.points / userStats.gamesPlayed
    ).toFixed(2);
  });

  return statsMap;
};

const SeasonalTables = () => {
  const { selectedLeague } = useLeagueContext();
  const [filteredHeaderOptions, setFilteredHeaderOptions] = useState([]);
  const [aggregatedPlayersData, setAggregatedPlayersData] = useState([]);
  const [gameDataMap, setGameDataMap] = useState({});

  useEffect(() => {
    if (selectedLeague && headerOptions[selectedLeague]) {
      setFilteredHeaderOptions(headerOptions[selectedLeague]);
    } else {
      setFilteredHeaderOptions([]);
    }

    fetchAllPlayersData();
  }, [selectedLeague]);

  const fetchAllPlayersData = async () => {
    try {
      // Set the date range for the entire season
      const currentDate = new Date();
      const startDate = new Date(currentDate.getFullYear(), 0, 1); // January 1st of the current year
      const endDate = currentDate;

      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      const response = await getGamesPlayedByDateRange(
        formattedStartDate,
        formattedEndDate
      );
      if (response.data && Array.isArray(response.data.data)) {
        const filteredData = response.data.data.filter(
          (game) => game.league === selectedLeague
        );
        const enhancedData = await Promise.all(
          filteredData.map(async (game) => {
            const userData = await getUserById(game.userId);
            return {
              ...game,
              co: userData.data.country || "-",
              state: userData.data.state || "-",
              city: userData.data.city || "-",
              player: userData.data.leagues[0]?.username || "-",
              team: userData.data.leagues[0]?.team || "-",
              BR:
                game.result?.perfectScore != null
                  ? parseFloat(game.result?.perfectScore).toFixed(2)
                  : "-",
              vegasOdds: game.result?.vegasOdds || {},
            };
          })
        );
        const aggregatedData = aggregatePlayerData(enhancedData);
        setAggregatedPlayersData(aggregatedData);

        const gameDataLookup = {};
        enhancedData.forEach((game) => {
          gameDataLookup[game._id] = game;
        });
        setGameDataMap(gameDataLookup);
      }
    } catch (error) {
      console.error("Error fetching all players data:", error);
    }
  };

  const aggregatePlayerData = (data) => {
    const playerMap = {};

    data.forEach((game) => {
      if (!playerMap[game.userId]) {
        playerMap[game.userId] = {
          co: game.co,
          state: game.state,
          city: game.city,
          player: game.player,
          userId: game.userId,
          team: game.team,
          gamesPlayed: 0,
          totalBR: 0,
          wins: 0,
          losses: 0,
          oneS: 0,
          oneS0: 0,
          oneSW2: 0,
          twoSW2: 0,
          ml: 0,
          spread: 0,
          ou: 0,
          games: [],
          totalNightlyPoints: 0,
          weeklyPoints: {},
          cgs: 0, // Initialize CGS
          maxCGS: 0, // Track max CGS for the season
        };
      }

      const player = playerMap[game.userId];
      player.gamesPlayed++;
      player.totalBR += parseFloat(game.BR) || 0;
      player.wins += game.BR > 0 ? 1 : 0;
      player.losses += game.BR <= 0 ? 1 : 0;
      player.oneS +=
        (game.result?.accuracyPoints?.home?.p1s || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s || 0);
      player.oneS0 +=
        (game.result?.accuracyPoints?.home?.p1s0 || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s0 || 0);
      player.oneSW2 +=
        (game.result?.accuracyPoints?.home?.p1s2p || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s2p || 0);
      player.twoSW2 +=
        (game.result?.accuracyPoints?.home?.p2s2p || 0) +
        (game.result?.accuracyPoints?.vistor?.p2s2p || 0);
      player.ml += parseFloat(
        game.result?.vegasOdds?.pickingFavorite ||
          game.result?.vegasOdds?.pickingUnderdog ||
          0
      );
      player.spread += parseFloat(
        game.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
          game.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
          0
      );
      player.ou += parseFloat(
        game.result?.vegasOdds?.pickingOver ||
          game.result?.vegasOdds?.pickingUnder ||
          0
      );
      player.games.push(game);

      // Calculate nightly points for this game
      const nightlyPoints = parseFloat(
        (game.result?.accuracyPoints?.home?.p1s || 0) +
          (game.result?.accuracyPoints?.vistor?.p1s || 0) +
          (game.result?.accuracyPoints?.home?.p1s2p || 0) +
          (game.result?.accuracyPoints?.vistor?.p1s2p || 0) +
          (game.result?.accuracyPoints?.home?.p2s2p || 0) +
          (game.result?.accuracyPoints?.vistor?.p2s2p || 0) +
          (game.result?.vegasOdds?.pickingFavorite ||
            game.result?.vegasOdds?.pickingUnderdog ||
            0) +
          (game.result?.vegasOdds?.pickingOver ||
            game.result?.vegasOdds?.pickingUnder ||
            0) +
          (game.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
            game.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
            0) +
          (game.BR || 0)
      );

      player.totalNightlyPoints += nightlyPoints;

      // Group points by week
      const gameDate = new Date(game.date);
      const weekNumber = getWeekNumber(gameDate);
      if (!player.weeklyPoints[weekNumber]) {
        player.weeklyPoints[weekNumber] = 0;
      }
      player.weeklyPoints[weekNumber] += nightlyPoints;

      // Update CGS
      if (game.result?.predictionMade) {
        player.cgs++;
        player.maxCGS = Math.max(player.maxCGS, player.cgs);
      } else {
        player.cgs = 0; // Reset CGS if no prediction was made
      }
    });

    // Calculate AWP for each player
    Object.values(playerMap).forEach((player) => {
      const totalWeeks = Object.keys(player.weeklyPoints).length;
      const totalPoints = Object.values(player.weeklyPoints).reduce(
        (sum, points) => sum + points,
        0
      );
      player.awp =
        totalWeeks > 0 ? (totalPoints / totalWeeks).toFixed(2) : "0.00";
    });

    return Object.values(playerMap);
  };

  // Helper function to get week number
  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  // Calculate TP points and ranks
  const calculateTPandRank = (games) => {
    const tpValues = games.map((row) => {
      return parseFloat(
        (row.result?.accuracyPoints?.home?.p1s || 0) +
          (row.result?.accuracyPoints?.vistor?.p1s || 0) +
          (row.result?.accuracyPoints?.home?.p1s2p || 0) +
          (row.result?.accuracyPoints?.vistor?.p1s2p || 0) +
          (row.result?.accuracyPoints?.home?.p2s2p || 0) +
          (row.result?.accuracyPoints?.vistor?.p2s2p || 0) +
          (row.result?.vegasOdds?.pickingFavorite ||
            row.result?.vegasOdds?.pickingUnderdog ||
            0) +
          (row.result?.vegasOdds?.pickingOver ||
            row.result?.vegasOdds?.pickingUnder ||
            0) +
          (row.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
            row.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
            0) +
          (row.BR || 0)
      ).toFixed(2);
    });

    // Rank the TP values
    const sortedTPValues = [...tpValues].sort((a, b) => b - a);
    const ranks = tpValues.map((tp) => sortedTPValues.indexOf(tp) + 1);

    return { tpValues, ranks };
  };

  // Calculate TP points and ranks
  const { tpValues, ranks } = calculateTPandRank(aggregatedPlayersData);

  // Define a function to render the appropriate columns based on the selected league
  const renderColumns = (row, index, ranks, tpValues, gameData) => {
    const Reg = calculateReg(row);
    const OT = calculateOT(row);
    const SO = calculateSO(row);
    const EI = calculateEI(row);

    switch (selectedLeague) {
      case "NHL":
        return (
          <>
            <td className="text-xs font-medium text-center">{Reg || "0"}</td>
            <td className="text-xs font-medium text-center">{OT || "-"}</td>
            <td className="text-xs font-medium text-center">{SO || "-"}</td>
          </>
        );
      case "NBA":
      case "NFL":
        return (
          <>
            <td className="text-xs font-medium text-center">{Reg || "0"}</td>
            <td className="text-xs font-medium text-center">{OT || "-"}</td>
          </>
        );
      case "MLB":
        return (
          <>
            <td className="text-xs font-medium text-center">{Reg || "0"}</td>
            <td className="text-xs font-medium text-center">{EI || "0"}</td>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {filteredHeaderOptions.map((item, ind) => (
              <th
                key={ind}
                className="text-xs font-medium"
                style={{
                  color: "#dba418",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {aggregatedPlayersData.length > 0 ? (
            aggregatedPlayersData.map((player, index) => {
              const ws = calculateWinStreak(player.games);
              const ls = calculateLossStreak(player.games);
              const cs = calculateCurrentStreak(player.games);
              const fPercentage = calculatePointsPercentage(
                player.games,
                "favoritePoints"
              );
              const uPercentage = calculatePointsPercentage(
                player.games,
                "underdogPoints"
              );
              const l10 = calculateL10(player.games);
              const favPointsPercentage = calculateFavoritePointsPercentage(
                player.games
              );
              const underdogPointsPercentage =
                calculateUnderdogPointsPercentage(player.games);

              return (
                <tr
                  key={index}
                  className="h-14 bg-[#181818] text-white separator row-slide-in"
                  style={{ animationDelay: `${index * 0.05}s` }}
                >
                  <td className="text-xs font-medium text-center">
                    {player.co || "-"}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.state || "-"}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.city || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#dba418", fontSize: "17px" }}
                  >
                    {player.player || "-"}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.team || "-"}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {index + 1}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {((player.wins / player.gamesPlayed) * 100).toFixed(2)}%
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.gamesPlayed}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.totalBR.toFixed(2)}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.wins}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.losses}
                  </td>
                  <td className="text-xs font-medium text-center">{cs}</td>
                  <td className="text-xs font-medium text-center">{ws}</td>
                  <td className="text-xs font-medium text-center">{ls}</td>
                  <td className="text-xs font-medium text-center">
                    {player.oneS.toFixed(2)}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.oneS0.toFixed(2)}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.oneSW2.toFixed(2)}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.twoSW2.toFixed(2)}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.ml.toFixed(2)}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.spread.toFixed(2)}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.ou.toFixed(2)}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {(player.totalNightlyPoints / player.gamesPlayed).toFixed(
                      2
                    )}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {player.awp}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {(player.totalBR / player.gamesPlayed).toFixed(2)}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {fPercentage}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {uPercentage}
                  </td>
                  {renderColumns(player, index)}
                  <td className="text-xs font-medium text-center">{l10}</td>
                  <td className="text-xs font-medium text-center">
                    {player.maxCGS}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {favPointsPercentage}
                  </td>
                  <td className="text-xs font-medium text-center">
                    {underdogPointsPercentage}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={filteredHeaderOptions.length}
                className="text-xs font-medium text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SeasonalTables;