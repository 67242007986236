import React, { useState, useEffect } from "react";
import TimeFormat from "../../services/TimeFormat";
import { useMediaQuery } from "@material-ui/core";
import "./GameCard.css";
import Switches from "../Switches.js";
import Modal from "react-modal";
import { addPrediction } from "../../Apis/predictions";
import displayToast from "../Alert/Alert";
import { useMutation } from "react-query";
import { useLeagueContext } from "../LeagueContext";
import Swal from "sweetalert2";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import { teamNameMappings } from "./data";

const DesktopCard = ({ gameData }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [Pick_Ei, setPick_Ei] = useState(false);

  const labelStyles = {
    borderBottom: "2px solid #c1931b",
    width: "90%",
    margin: "3px",
    fontSize: "9px",
    textAlign: "center",
    color: "white",
    paddingBottom: "3px",
  };

  const [pick_visitor, setPickVisitor] = useState("");
  const [pick_home, setPickHome] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedGameData, setEditedGameData] = useState({ ...gameData });
  const [Pick_Reg, setPick_Reg] = useState(false);
  const [Pick_ot, setPick_ot] = useState(false);
  const [Pick_so, setPick_so] = useState(false);
  const [Pick_num_ot, setPick_num_ot] = useState(0);
  const [Pick_num_ei, setPick_num_ei] = useState(0);
  const [Pick_num_so, setPick_num_so] = useState(0);
  const [inputChanged, setInputChanged] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [isLockedIn, setIsLockedIn] = useState(false);
  const [buttonText, setButtonText] = useState(
    <>
      <LockOpenIcon /> LOCK IT IN
    </>
  );
  const [enterPickText, setEnterPickText] = useState(
    <>
      <EditIcon /> ENTER PICK
    </>
  );

  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: "initial",
    color: "black",
  });

  const [previousValues, setPreviousValues] = useState({
    pick_visitor: "",
    pick_home: "",
  });

  const cardId = gameData._id;

  const { selectedLeague } = useLeagueContext();

  const userId = localStorage.getItem("_id");

  const [gameEnding, setGameEnding] = useState(""); // State for gameEnding

  const isGameStarted = () => {
    const gameTime = new Date(gameData.gameTime); // Assuming gameTime is in Atlantic Daylight Time (ADT)
    const currentTime = new Date(); // Current time

    return currentTime >= gameTime;
  };

  // Disable buttons based on game start time
  const isGameDisabled = isGameStarted();

  const handleEnterPick = () => {
    if (isGameDisabled) {
      displayToast(
        "The game has started. You can no longer edit your prediction.",
        "error"
      );
      return;
    }

    const invalidFields = [];
    if (!pick_visitor) invalidFields.push("pick_visitor");
    if (!pick_home) invalidFields.push("pick_home");
    if (!Pick_Reg && !Pick_ot && !Pick_so) invalidFields.push("pick_switch");

    setInvalidFields(invalidFields);

    if (invalidFields.length > 0) {
      Swal.fire({
        title: "Error",
        text: "Select one of the radio buttons.",
        icon: "error",
        background: "#212121",
        color: "white",
      });
      return;
    }

    // Save to localStorage
    const dataToSave = {
      gameData: gameData._id,
      pick_visitor,
      pick_home,
      gameEnding,
      userId,
      Pick_num_ot,
      Pick_num_ei,
      Pick_num_so,
      Pick_so,
      Pick_ot,
      Pick_Reg,
    };
    localStorage.setItem(gameData._id, JSON.stringify(dataToSave));
    displayToast("Prediction saved. Editable until game starts.", "success");
  };
  // console.log({
  //   "Game Data ID": gameData._id,
  // });

  // Function to check lock status from localStorage on mount
  useEffect(() => {
    const savedLockStatus = localStorage.getItem(`isLockedIn_${gameData._id}`);
    if (savedLockStatus === "true") {
      setIsLockedIn(true);
    }
  }, [gameData._id]);

  const handleLockIn = () => {
    if (isGameDisabled) {
      displayToast(
        "The game has started. You can no longer edit your prediction.",
        "error"
      );
      return;
    }

    const invalidFields = [];
    const visitorScore = parseInt(pick_visitor);
    const homeScore = parseInt(pick_home);

    if (!pick_visitor || isNaN(visitorScore))
      invalidFields.push("pick_visitor");
    if (!pick_home || isNaN(homeScore)) invalidFields.push("pick_home");
    if (!Pick_Reg && !Pick_ot && !Pick_so && !Pick_Ei)
      invalidFields.push("pick_switch");

    setInvalidFields(invalidFields);

    if (invalidFields.length > 0) {
      Swal.fire({
        // title: "Error",
        text: "PLEASE SELECT HOW GAME WILL END",
        icon: "error",
        background: "#212121",
        color: "white",
      });
      return;
    }

    let showAlert = false;
    let alertMessage = "";
    let showError = false;
    let errorMessage = "";

    // Validation logic for specific leagues...
    // (NHL, NBA, MLB, NFL score checks)

    if (showError) {
      displayToast(errorMessage, "error");
    } else if (showAlert) {
      Swal.fire({
        title: "Are you sure?",
        text: alertMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        background: "#212121",
        color: "white",
      }).then((result) => {
        if (result.isConfirmed) {
          lockInPrediction();
        }
      });
    } else {
      lockInPrediction();
      
    }
  };

  // Lock in prediction and prevent further edits
  const lockInPrediction = () => {
    const timestamp = new Date().toISOString();

    const dataToSave = {
      gameData: gameData._id,
      pick_visitor,
      pick_home,
      gameEnding,
      userId,
      Pick_num_ot,
      Pick_num_ei,
      Pick_num_so,
      Pick_so,
      Pick_ot,
      Pick_Reg,
      league: selectedLeague,
    };

    mutate(dataToSave); // Send to backend

    // Set the lock-in state to true so the warning doesn't appear
    setIsLockedIn(true);
    localStorage.setItem(`isLockedIn_${gameData._id}`, "true"); // Save the lock status
    // displayToast("Prediction locked.", "success");

    // Remove the local storage entry
    localStorage.removeItem(gameData._id);
  };

  // Warn user if they try to close the tab without locking in
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isLockedIn) {
        // Set a custom warning message
        const message =
          "You haven't locked in your prediction yet. Do you want to leave without locking it in?";
        event.returnValue = message; // For most modern browsers
        return message; // For older browsers
      }
    };

    // Attach event listener if the prediction is not locked
    if (!isLockedIn) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    // Cleanup the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLockedIn]);

  // Call this function to send the locked-in prediction to the backend when game starts
  const sendPredictionToBackend = () => {
    if (isGameStarted()) {
      const dataFromLocalStorage = localStorage.getItem(gameData._id);
      if (dataFromLocalStorage) {
        const parsedData = JSON.parse(dataFromLocalStorage);
        mutate(parsedData);
        localStorage.removeItem(gameData._id);
        setIsLockedIn(true); // Lock the prediction
        // displayToast("Prediction locked.", "success");
      }
    }
  };
  // Update button text based on lock status
  useEffect(() => {
    if (isLockedIn) {
      setButtonText(
        <>
          LOCKED <LockIcon />
        </>
      );
      setButtonStyle({ backgroundColor: "initial", color: "red" });
    } else {
      setButtonText(
        <>
          <LockOpenIcon /> LOCK IT IN
        </>
      );
      setButtonStyle({ backgroundColor: "initial", color: "black" });
    }
  }, [isLockedIn]);

  // Check if game has started and auto-lock the prediction
  useEffect(() => {
    if (isGameStarted()) {
      sendPredictionToBackend();
    }
  }, [gameData]);

  const { mutate, isLoading, isError, data, error, reset } = useMutation(
    (data) => addPrediction(data),
    {
      onSuccess: (data) => {
        // displayToast("Prediction added successfully", "success");
      },
      onError: (error) => {
        displayToast("Error while adding the prediction", "error");
      },
    }
  );

  useEffect(() => {
    if (isLockedIn) {
      // If the "LOCK IT IN" button is clicked, show EditOffIcon
      setEnterPickText(
        <>
          {/* <EditOffIcon /> */}
          GAME PLAYED
        </>
      );
    } else {
      // If the "LOCK IT IN" button is not clicked, show EditIcon
      setEnterPickText(
        <>
          <EditIcon /> ENTER
          <br />
          PICK
        </>
      );
    }
  }, [isLockedIn]);

  useEffect(() => {
    const date = new Date(gameData?.gamedate);
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
  }, [gameData]);

  useEffect(() => {
    const storedVisitor = localStorage.getItem(`pick_visitor_${cardId}`);
    const storedHome = localStorage.getItem(`pick_home_${cardId}`);

    if (storedVisitor) {
      setPickVisitor(storedVisitor);
    }
    if (storedHome) {
      setPickHome(storedHome);
    }
  }, [cardId]);

  // Handle input change for visitor (allow only numbers)
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Allows only numeric input
      if (value !== pick_visitor) {
        setInputChanged(true);
      }
      setPickVisitor(value);
      localStorage.setItem(`pick_visitor_${cardId}`, value); // Save to localStorage with unique key

      // Set expiration timestamp
      const expirationTime = new Date();
      expirationTime.setHours(2, 0, 0, 0); // 2 AM
      localStorage.setItem(
        `expiration_${cardId}`,
        expirationTime.toISOString()
      );
    }
  };

  // Handle input change for home (allow only numbers)
  const handleHomeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Allows only numeric input
      if (value !== pick_home) {
        setInputChanged(true);
      }
      setPickHome(value);
      localStorage.setItem(`pick_home_${cardId}`, value); // Save to localStorage with unique key

      // Set expiration timestamp
      const expirationTime = new Date();
      expirationTime.setHours(2, 0, 0, 0); // 2 AM
      localStorage.setItem(
        `expiration_${cardId}`,
        expirationTime.toISOString()
      );
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (inputChanged) {
        const message =
          "You have unsaved changes, are you sure you want to leave?";
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [inputChanged]);

  const handleSaveEdit = () => {
    setIsModalOpen(false);
    setInputChanged(false);
    setPreviousValues({ pick_visitor, pick_home });
    console.log("Saved data:", editedGameData);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const renderSwitches = (team) => (
    <Switches
      league={gameData?.league}
      season={gameData?.seasonflag}
      setPick_num_ot={setPick_num_ot}
      setPick_num_ei={setPick_num_ei}
      setPick_num_so={setPick_num_so}
      setPick_so={setPick_so}
      setPick_ot={setPick_ot}
      setPick_Reg={setPick_Reg}
      setPick_Ei={setPick_Ei}
      uniqueId={gameData._id}
      glowing={invalidFields.includes("pick_switch")}
      setGameEnding={setGameEnding} // Pass the function to update gameEnding
    />
  );

  return (
    <>
      <div className="game-card grid col-span-2 xl:col-span-1">
        <div
          className="flex justify-between"
          // style={{ border: "2px solid red" }}
        >
          <div style={{ paddingRight: "3.3%" }} className="flex flex-col">
            {/* <div
              className="game-time test-size"
              style={{
                display: "flex",
                WebkitTextStroke: "0.3px black",
                textStroke: "0.3px black",
                textShadow: "3px 7px 7px rgba(255, 0, 0, 0.25)",
                fontSize: isMobile ? "9px" : "12px",
              }}
            >
              {TimeFormat(gameData?.time)}
            </div> */}
            <div
              style={{
                fontSize: "12px",
                marginLeft: "-10px",
                // WebkitTextStroke: "0.3px black", // Subtle black stroke
                // color: "#fff", // White text color
                // textShadow: "1px 1px 2px rgba(255, 255, 255, 0.8)", // White shadow
                background:
                  "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
              className="game-date"
            >
              {gameData.gamedate}
            </div>
            {/* <div className="game-date">{gameData.gamedate}</div> &nbsp; */}
            <input
              type="text"
              id={`pick_visitor_${cardId}`}
              className={`score-input card-input mb-2  ${
                invalidFields.includes("pick_visitor") ? "glowing-border" : ""
              }`}
              value={pick_visitor}
              onChange={handleInputChange}
              disabled={isLockedIn} // Disable button when locked
            />
          </div>

          <div className="flex flex-col">
            <div
              className="game-time test-size font-inter mb-3"
              style={{
                // WebkitTextStroke: "0.3px black",
                fontSize: isMobile ? "10px" : "10px",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  marginLeft: "15px",
                  WebkitTextStroke: "0.3px black", // Subtle black stroke
                  color: "#fff", // White text color
                  textShadow: "1px 1px 2px rgba(255, 255, 255, 0.8)", // White shadow
                }}
                className="game-date"
              >
                {/* {gameData.gamedate} */}
              </div>
              {/* Team */}
            </div>
            <div
              style={{
                width: "6rem",
                height: "3rem",
                display: "flex",
                marginRight: "20px",
                flexDirection: "column",
                alignItems: "center", // Align items horizontally (center)
                justifyContent: "center", // Align items vertically (center)
                textAlign: "center", // Align text within the label
              }}
              className="box px-7 "
            >
              <div
                className="team-names"
                style={{
                  fontSize: "12px",
                  background:
                    "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
              >
                {teamNameMappings[gameData?.visitor] || gameData?.visitor}
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div
              className="game-time test-size font-inter mb-3"
              style={{
                // WebkitTextStroke: "0.3px black",
                fontSize: isMobile ? "10px" : "13px",
                margin: "auto",
                // color: "#c1931b",
                // textShadow: "0px 0px 5px rgba(0, 255, 0, 1)",
                background:
                  "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Money Line
            </div>

            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["v-ml"]}</label>
              <label
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["v-ml-points"]} PTS
              </label>
            </div>
          </div>

          <div className="flex flex-col">
            <div
              className="game-time test-size font-inter mb-3"
              style={{
                // WebkitTextStroke: "0.3px black",
                fontSize: isMobile ? "10px" : "13px",
                // color: "#c1931b",
                // textShadow: "0px 0px 5px rgba(0, 255, 0, 1)",
                margin: "auto",
                background:
                  "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Spread
            </div>
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["v-sprd"]}</label>
              <label
                className="text-white"
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["v-sprd-points"]} PTS
              </label>
            </div>
          </div>
          <div className="flex flex-col">
            <div
              className="game-time test-size font-inter mb-3"
              style={{
                // WebkitTextStroke: "0.3px black",
                fontSize: isMobile ? "10px" : "13px",
                // color: "#c1931b",
                // textShadow: "0px 0px 5px rgba(0, 255, 0, 1)",
                margin: "auto",
                background:
                  "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Over/Under
            </div>
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["v-ou"]}</label>
              <label
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["v-ou-points"]} PTS
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-1">
          <div
            className="line"
            style={{
              width: "8%",
              margin: "20px 0px",
              background: "white",
              marginLeft: "8px",
            }}
          ></div>
          <div
            className="line"
            style={{
              width: "19%",
              margin: "20px -14px -20px 0px",
              background: "white",
              marginLeft: "8px",
            }}
          ></div>
          <div
            className="line"
            style={{
              width: "59.6%",
              background: "white",
              margin: "20px 0px",
            }}
          ></div>
        </div>

        <div className="flex justify-between">
          <div className="flex flex-col" style={{ paddingRight: "4.5%" }}>
            <input
              type="text"
              id={`pick_home_${cardId}`}
              className={`score-input card-input mb-2 mt-auto ${
                invalidFields.includes("pick_home") ? "glowing-border" : ""
              }`}
              value={pick_home}
              onChange={handleHomeChange}
              disabled={isLockedIn} // Disable button when locked
            />
          </div>

          <div className="flex flex-col justify-start">
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
                display: "flex",
                marginRight: "20px",
                flexDirection: "column",
                alignItems: "center", // Align items horizontally (center)
                justifyContent: "center", // Align items vertically (center)
                textAlign: "center", // Align text within the label
              }}
            >
              <div
                className="upside-down"
                style={{
                  fontSize: "12px",
                  background:
                    "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
              >
                {teamNameMappings[gameData?.home] || gameData?.home}
              </div>
            </div>
          </div>

          <div
            className="flex flex-col justify-start"
            style={{
              // WebkitTextStroke: "0.3px black",
              fontSize: isMobile ? "10px" : "12px",
            }}
          >
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["h-ml"]}</label>
              <label
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["h-ml-points"]} PTS
              </label>
            </div>
          </div>

          <div
            className="flex flex-col justify-start"
            style={{
              // WebkitTextStroke: "0.3px black",
              fontSize: isMobile ? "10px" : "11px",
            }}
          >
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["h-sprd"]}</label>
              <label style={{ fontSize: "8.5px", marginTop: "3px" }}>
                {gameData?.["h-sprd-points"]} PTS
              </label>
            </div>
          </div>
          <div
            className="flex flex-col"
            style={{
              // WebkitTextStroke: "0.3px black",
              fontSize: isMobile ? "10px" : "12px",
            }}
          >
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["h-ou"]}</label>
              <label
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["h-ou-points"]} PTS
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-1">
          <div
            className="line"
            style={{
              width: "8%",
              // height : '20px',
              background: "white",
              margin: "20px 0px 20px  0px",
              marginLeft: "8px",
            }}
          ></div>
          <div
            className="line"
            style={{
              width: "82.7%",
              background: "white",
              margin: "20px 0px 20px  0px",
            }}
          ></div>
        </div>

        <div className="flex justify-between items-center">
          <div className="card-id"></div>
          <div
            className="game-time test-size"
            style={{
              // display: "flex",
              marginTop: "0px",
              marginLeft: "-10px",
              WebkitTextStroke: "0.3px black",
              textStroke: "0.3px black",
              textShadow: "3px 7px 7px rgba(255, 0, 0, 0.25)",
              fontSize: "12px",
            }}
          >
            {TimeFormat(gameData?.time)}
            
          </div>

          <Switches
            league={gameData?.league}
            season={gameData?.seasonflag}
            setPick_num_ot={setPick_num_ot}
            setPick_num_ei={setPick_num_ei}
            setPick_num_so={setPick_num_so}
            setPick_so={setPick_so}
            setPick_ot={setPick_ot}
            setPick_Reg={setPick_Reg}
            setPick_Ei={setPick_Ei}
            uniqueId={gameData._id}
            glowing={invalidFields.includes("pick_switch")}
            setGameEnding={setGameEnding} // Pass the function to update gameEnding
          />

          <div
            className="button-pick"
            style={{
              display: "flex",
              columnGap: "2vh",
              // border: "2px solid blue",
            }}
          >
            <button
              className="card-btn-enter mt-1"
              style={{
                fontSize: isMobile ? "10px" : "11px",
                color: "white",
                width: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px", // Space between icon and text
              }}
              onClick={handleEnterPick}
              disabled={isLockedIn} // Disable button when locked
            >
              {enterPickText}
            </button>{" "}
            <button
              className="card-btn mt-1"
              style={{
                width: "90px",
                fontSize: isMobile ? "10px" : "11px",
                ...buttonStyle, // Apply the button style dynamically based on the state
                display: "flex",
                alignItems: "center", // Aligns text and icon vertically
                justifyContent: "center",
                gap: "6px", // Space between icon and text
              }}
              onClick={handleLockIn}
              disabled={isLockedIn} // Disable button when locked
            >
              {buttonText}
            </button>{" "}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Edit Game Data"
      >
        <h2>Edit Game Data</h2>
        <form>
          <input
            type="text"
            value={editedGameData?.visitor}
            onChange={(e) =>
              setEditedGameData({ ...editedGameData, visitor: e.target.value })
            }
          />
          <button onClick={handleSaveEdit}>Save</button>
          <button onClick={handleModalClose}>Cancel</button>
        </form>
      </Modal>
    </>
  );
};

export default DesktopCard;
