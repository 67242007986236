export const headerOptions = {
    NHL: [
      "Visitor",
      "Home",
      "Final",
      "Prediction",
      "Time",
      "CO",
      "STATE",
      // "PROV",
      "CITY ",
      "PLAYER",
      "R",
      "TP",
  
      "BR",
      "ML",
      "O/U",
      "Sprd",
      "1S",
      "1S 0", // Added for Hockey
      "Reg",
      "OT",
      "SO",
      // "PLAYOFFS",
      // "VISITOR",
      // "HOME",
      // "Final",
      // "Prediction",
      // "Time",
      // "CO",
      // "CITY PROV/STATE",
      // "PLAYER",
      // "R",
      // "TP",
      // "BR",
      // "ML",
      // "O/U",
      // "Sprd",
      // "1S",
      // "1S 0",
      // "Reg",
      // "OT",
    ],
    NBA: [
      "Visitor",
      "Home",
      "Final",
      "Prediction",
      "Time",
      "CO",
      "STATE",
      // "PROV",
      "CITY ",
      "PLAYER",
      "R",
      "TP",
      "BR",
      "ML",
      "Sprd",
      "1S",
      "1S 0",
      "1SW3",
      "2SW3",
      "1SW7",
      "2SW7",
      "Reg", // Remove EI (Keep OT)
      "OT",
    ],
    MLB: [
      "Visitor",
      "Home",
      "Final",
      "Prediction",
      "Time",
      "CO",
      "STATE",
      // "PROV",
      "CITY ",
      "PLAYER",
      "R",
      "TP",
      "BR",
      "ML",
      "O/U",
      "Sprd",
      "1S",
      "1S0",
      "1SW2",
      "2SW2",
  
      "Reg",
      "EI",
    ],
    NFL: [
      // REGULAR SEASON
      "Visitor",
      "Home",
      "Final",
      "Prediction",
      "Time",
      "CO",
      "STATE",
      // "PROV",
      "CITY ",
      "PLAYER",
      "R",
      "TP",
      "BR",
      "ML",
      "O/U",
      "Sprd",
      "1S",
      "1S0",
      "2S0",
      "1SW3",
      "2SW3",
      "1SW7",
      "2SW7",
      "Reg",
      "OT",
  
      // Play Offs
      // "Visitor",
      // "Home",
      // "Final",
      // "Prediction",
      // "Time",
      // "CO",
      // "CITY PROV/STATE",
      // "PLAYER",
      // "R",
      // "TP",
      // "BR",
      // "ML",
      // "O/U",
      // "Sprd",
      // "1S",
      // "1S0",
      // "2S0",
      // "1SW3",
      // "2SW3",
      // "1SW7",
      // "2SW7",
      // "Reg",
      // "OT",
    ],
    WNBA: [
      "Visitor",
      "Home",
      "Final",
      "Prediction",
      "Time",
      "CO",
      "STATE",
      // "PROV",
      "CITY ",
      "PLAYER",
      "R",
      "TP",
      "BR",
      "ML",
      "Sprd",
      "1S",
      "1S 0",
      "1SW3",
      "2SW3",
      "1SW7",
      "2SW7",
      "Reg", // Remove EI (Keep OT)
      "OT",
    ],
    CFL: [
      // REGULAR SEASON
      "Visitor",
      "Home",
      "Final",
      "Prediction",
      "Time",
      "CO",
      "STATE",
      // "PROV",
      "CITY ",
      "PLAYER",
      "R",
      "TP",
      "BR",
      "ML",
      "O/U",
      "Sprd",
      "1S",
      "1S0",
      "2S0",
      "1SW3",
      "2SW3",
      "1SW7",
      "2SW7",
      "Reg",
      "OT",
  
      // Play Offs
      // "Visitor",
      // "Home",
      // "Final",
      // "Prediction",
      // "Time",
      // "CO",
      // "CITY PROV/STATE",
      // "PLAYER",
      // "R",
      // "TP",
      // "BR",
      // "ML",
      // "O/U",
      // "Sprd",
      // "1S",
      // "1S0",
      // "2S0",
      // "1SW3",
      // "2SW3",
      // "1SW7",
      // "2SW7",
      // "Reg",
      // "OT",
    ],
    NCAAF: [
      // REGULAR SEASON
      "Visitor",
      "Home",
      "Final",
      "Prediction",
      "Time",
      "CO",
      "STATE",
      // "PROV",
      "CITY ",
      "PLAYER",
      "R",
      "TP",
      "BR",
      "ML",
      "O/U",
      "Sprd",
      "1S",
      "1S0",
      "2S0",
      "1SW3",
      "2SW3",
      "1SW7",
      "2SW7",
      "Reg",
      "OT",
  
      // Play Offs
      // "Visitor",
      // "Home",
      // "Final",
      // "Prediction",
      // "Time",
      // "CO",
      // "CITY PROV/STATE",
      // "PLAYER",
      // "R",
      // "TP",
      // "BR",
      // "ML",
      // "O/U",
      // "Sprd",
      // "1S",
      // "1S0",
      // "2S0",
      // "1SW3",
      // "2SW3",
      // "1SW7",
      // "2SW7",
      // "Reg",
      // "OT",
    ],
    UFL: [
      // REGULAR SEASON
      "Visitor",
      "Home",
      "Final",
      "Prediction",
      "Time",
      "CO",
      "STATE",
      // "PROV",
      "CITY ",
      "PLAYER",
      "R",
      "TP",
      "BR",
      "ML",
      "O/U",
      "Sprd",
      "1S",
      "1S0",
      "2S0",
      "1SW3",
      "2SW3",
      "1SW7",
      "2SW7",
      "Reg",
      "OT",
  
      // Play Offs
      // "Visitor",
      // "Home",
      // "Final",
      // "Prediction",
      // "Time",
      // "CO",
      // "CITY PROV/STATE",
      // "PLAYER",
      // "R",
      // "TP",
      // "BR",
      // "ML",
      // "O/U",
      // "Sprd",
      // "1S",
      // "1S0",
      // "2S0",
      // "1SW3",
      // "2SW3",
      // "1SW7",
      // "2SW7",
      // "Reg",
      // "OT",
    ],
    NCCA: [
      // NCCA header options
    ],
    NCAAB: [
      "Visitor",
      "Home",
      "Final",
      "Prediction",
      "Time",
      "CO",
      "STATE",
      // "PROV",
      "CITY ",
      "PLAYER",
      "R",
      "TP",
      "BR",
      "ML",
      "Sprd",
      "1S",
      "1S 0",
      "1SW3",
      "2SW3",
      "1SW7",
      "2SW7",
      "Reg", // Remove EI (Keep OT)
      "OT",
    ],
  
    // MLB Teams
    "Arizona Diamondbacks": "Arizona",
    "Atlanta Braves": "Atlanta",
    "Baltimore Orioles": "Baltimore",
    "Boston Red Sox": "Boston",
    "Chicago Cubs": "Cubs",
    "Chicago White Sox": "White Sox",
    "Cincinnati Reds": "Cincinnati",
    "Cleveland Guardians": "Cleveland",
    "Colorado Rockies": "Colorado",
    "Detroit Tigers": "Detroit",
    "Houston Astros": "Houston",
    "Kansas City Royals": "Kansas City",
    "Los Angeles Angels": "Angels",
    "Los Angeles Dodgers": "Dodgers",
    "Miami Marlins": "Miami",
    "Milwaukee Brewers": "Milwaukee",
    "Minnesota Twins": "Minnesota",
    "New York Mets": "Mets",
    "New York Yankees": "Yankees",
    "Oakland Athletics": "Oakland",
    "Philadelphia Phillies": "Philadelphia",
    "Pittsburgh Pirates": "Pittsburgh",
    "San Diego Padres": "San Diego",
    "San Francisco Giants": "San Francisco",
    "Seattle Mariners": "Seattle",
    "St. Louis Cardinals": "St. Louis",
    "Tampa Bay Rays": "Tampa Bay",
    "Texas Rangers": "Texas",
    "Toronto Blue Jays": "Toronto",
    "Washington Nationals": "Washington",
  
    // NBA Teams
    "Atlanta Hawks": "Atlanta",
    "Boston Celtics": "Boston",
    "Brooklyn Nets": "Brooklyn",
    "Charlotte Hornets": "Charlotte",
    "Chicago Bulls": "Chicago",
    "Cleveland Cavaliers": "Cleveland",
    "Dallas Mavericks": "Dallas",
    "Denver Nuggets": "Denver",
    "Detroit Pistons": "Detroit",
    "Golden State Warriors": "Golden State",
    "Houston Rockets": "Houston",
    "Indiana Pacers": "Indiana",
    "Los Angeles Clippers": "Clippers",
    "Los Angeles Lakers": "Lakers",
    "Memphis Grizzlies": "Memphis",
    "Miami Heat": "Miami",
    "Milwaukee Bucks": "Milwaukee",
    "Minnesota Timberwolves": "Minnesota",
    "New Orleans Pelicans": "New Orleans",
    "New York Knicks": "New York",
    "Oklahoma City Thunder": "Oklahoma",
    "Orlando Magic": "Orlando",
    "Philadelphia 76ers": "Philadelphia",
    "Phoenix Suns": "Phoenix",
    "Portland Trail Blazers": "Portland",
    "Sacramento Kings": "Sacramento",
    "San Antonio Spurs": "San Antonio",
    "Toronto Raptors": "Toronto",
    "Utah Jazz": "Utah",
    "Washington Wizards": "Washington",
  
    // WNBA Teams
    "Atlanta Dream": "Atlanta",
    "Chicago Sky": "Chicago",
    "Connecticut Sun": "Connecticut",
    "Dallas Wings": "Dallas",
    "Indiana Fever": "Indiana",
    "Las Vegas Aces": "Las Vegas",
    "Los Angeles Sparks": "Los Angeles",
    "Minnesota Lynx": "Minnesota",
    "New York Liberty": "New York",
    "Phoenix Mercury": "Phoenix",
    "Seattle Storm": "Seattle",
    "Washington Mystics": "Washington",
  
    // NHL Teams
    "Anaheim Ducks": "Anaheim",
    "Arizona Coyotes": "Arizona",
    "Boston Bruins": "Boston",
    "Buffalo Sabres": "Buffalo",
    "Calgary Flames": "Calgary",
    "Carolina Hurricanes": "Carolina",
    "Chicago Blackhawks": "Chicago",
    "Colorado Avalanche": "Colorado",
    "Columbus Blue Jackets": "Columbus",
    "Dallas Stars": "Dallas",
    "Detroit Red Wings": "Detroit",
    "Edmonton Oilers": "Edmonton",
    "Florida Panthers": "Florida",
    "Los Angeles Kings": "Los Angeles",
    "Minnesota Wild": "Minnesota",
    "Montréal Canadiens": "Montreal",
    "Nashville Predators": "Nashville",
    "New Jersey Devils": "New Jersey",
    "New York Islanders": "Islanders",
    "New York Rangers": "Rangers",
    "Ottawa Senators": "Ottawa",
    "Philadelphia Flyers": "Philadelphia",
    "Pittsburgh Penguins": "Pittsburgh",
    "San Jose Sharks": "San Jose",
    "Seattle Kraken": "Seattle",
    "St. Louis Blues": "St. Louis",
    "Tampa Bay Lightning": "Tampa Bay",
    "Toronto Maple Leafs": "Toronto",
    "Utah Hockey Club": "Utah",
    "Vancouver Canucks": "Vancouver",
    "Vegas Golden Knights": "Vegas",
    "Washington Capitals": "Washington",
    "Winnipeg Jets": "Winnipeg",
  
    // NFL Teams
    "Arizona Cardinals": "Arizona",
    "Atlanta Falcons": "Atlanta",
    "Baltimore Ravens": "Baltimore",
    "Buffalo Bills": "Buffalo",
    "Carolina Panthers": "Carolina",
    "Chicago Bears": "Chicago",
    "Cincinnati Bengals": "Cincinnati",
    "Cleveland Browns": "Cleveland",
    "Dallas Cowboys": "Dallas",
    "Denver Broncos": "Denver",
    "Detroit Lions": "Detroit",
    "Green Bay Packers": "Green",
    "Houston Texans": "Houston",
    "Indianapolis Colts": "Indianapolis",
    "Jacksonville Jaguars": "Jacksonville",
    "Kansas City Chiefs": "Kansas",
    "Las Vegas Raiders": "Las Vegas",
    "Los Angeles Chargers": "Chargers",
    "Los Angeles Rams": "Rams",
    "Miami Dolphins": "Miami",
    "Minnesota Vikings": "Minnesota",
    "New England Patriots": "New",
    "New Orleans Saints": "New",
    "New York Giants": "Giants",
    "New York Jets": "Jets",
    "Philadelphia Eagles": "Philadelphia",
    "Pittsburgh Steelers": "Pittsburgh",
    "San Francisco 49ers": "San Francisco",
    "Seattle Seahawks": "Seattle",
    "Tampa Bay Buccaneers": "Tampa",
    "Tennessee Titans": "Tennessee",
    "Washington Commanders": "Washington",
  
    // CFL Teams
    "BC Lions": "BC",
    "Calgary Stampeders": "Calgary",
    "Edmonton Elks": "Edmonton",
    "Saskatchewan Roughriders": "Saskatchewan",
    "Winnipeg Blue Bombers": "Winnipeg",
    "Hamilton Tiger-Cats": "Hamilton",
    "Montreal Alouettes": "Montreal",
    "Ottawa Redblacks": "Ottawa",
    "Toronto Argonauts": "Toronto",
  
    // UFL Teams
    "Birmingham Stallions": "Birmingham",
    "Houston Roughnecks": "Houston",
    "Memphis Showboats": "Memphis",
    "Michigan Panthers": "Michigan",
    "Arlington Renegades": "Arlington",
    "D.C. Defenders": "D.C.",
    "San Antonio Brahmas": "San Antonio",
    "St. Louis Battlehawks": "St. Louis",
  };
  
  export const replaceTeamNames = (gameData) => {
    return {
      ...gameData,
      visitor: headerOptions[gameData.visitor] || gameData.visitor,
      home: headerOptions[gameData.home] || gameData.home,
    };
  };
  