import React, { useEffect, useState } from "react";
import "./Styles.css";
import { useLeagueContext } from "../LeagueContext";
import { getUserById } from "../../Apis/auth";
import { getGamePlayedByUserId } from "../../Apis/predictions";
import { getGamesPlayedByDateRange } from "../../Apis/predictions";
import { headerOptions } from "./data"; // Import headerOptions

const calculateReg = (row) => {
  return row.result?.endingsPoints?.pickRegulation || 0;
};

const calculateOT = (row) => {
  return row.result?.endingsPoints?.pickOverTime || 0;
};

const calculateSO = (row) => {
  return row.result?.endingsPoints?.pickShootout || 0;
};

// Utility functions for calculations
const calculateWinStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  games.forEach((game) => {
    if (game.BR > 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateLossStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  games.forEach((game) => {
    if (game.BR <= 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateCurrentStreak = (games) => {
  let streak = "";
  let wins = 0,
    losses = 0;
  for (let i = games.length - 1; i >= 0; i--) {
    if (games[i].BR > 0) {
      wins++;
      if (losses > 0) break;
    } else {
      losses++;
      if (wins > 0) break;
    }
  }
  if (wins > 0) streak = `W${wins}`;
  if (losses > 0) streak = `L${losses}`;
  return streak;
};

const calculatePointsPercentage = (games, condition) => {
  const totalPoints = games.reduce(
    (acc, game) => acc + (game[condition] || 0),
    0
  );
  const maxPoints = games.length * 100; // Assume max points per condition is 100
  return ((totalPoints / maxPoints) * 100).toFixed(2);
};

const calculateL10 = (games) => {
  const last10Games = games.slice(-10);
  let winCount = 0,
    lossCount = 0;
  last10Games.forEach((game) => {
    if (game.BR > 0) winCount++;
    else lossCount++;
  });
  return `${winCount}/${lossCount}`;
};

const calculateFavoritePointsPercentage = (games) => {
  // Placeholder logic for favorite points calculation
  const favoritePoints = games.reduce(
    (acc, game) => acc + (game.favoritePoints || 0),
    0
  );
  const maxFavoritePoints = games.length * 100; // Assume max points for favorites is 100
  return ((favoritePoints / maxFavoritePoints) * 100).toFixed(2);
};

const calculateUnderdogPointsPercentage = (games) => {
  // Placeholder logic for underdog points calculation
  const underdogPoints = games.reduce(
    (acc, game) => acc + (game.underdogPoints || 0),
    0
  );
  const maxUnderdogPoints = games.length * 100; // Assume max points for underdogs is 100
  return ((underdogPoints / maxUnderdogPoints) * 100).toFixed(2);
};

// Function to calculate user statistics
const calculateUserStats = (games) => {
  const statsMap = {};

  games.forEach((game) => {
    const userId = game.userId;

    if (!statsMap[userId]) {
      statsMap[userId] = {
        gamesPlayed: 0,
        wins: 0,
        losses: 0,
        points: 0,
      };
    }

    statsMap[userId].gamesPlayed += 1;
    statsMap[userId].points += game.BR ? parseFloat(game.BR) : 0;

    if (game.BR > 0) {
      statsMap[userId].wins += 1;
    } else {
      statsMap[userId].losses += 1;
    }
  });

  Object.keys(statsMap).forEach((userId) => {
    const userStats = statsMap[userId];
    userStats.winPercentage = (
      (userStats.wins / userStats.gamesPlayed) *
      100
    ).toFixed(2);
    userStats.avgPointsPerGame = (
      userStats.points / userStats.gamesPlayed
    ).toFixed(2);
  });

  return statsMap;
};

const NHLStandingTables = () => {
  const { selectedLeague } = useLeagueContext();
  const [filteredHeaderOptions, setFilteredHeaderOptions] = useState([]);
  const [allPlayersData, setAllPlayersData] = useState([]);
  const [gameDataMap, setGameDataMap] = useState({});
  const [userStatsMap, setUserStatsMap] = useState({});
  const id = localStorage.getItem("_id");

  useEffect(() => {
    if (selectedLeague && headerOptions[selectedLeague]) {
      setFilteredHeaderOptions(headerOptions[selectedLeague]);
    } else {
      setFilteredHeaderOptions([]);
    }

    fetchAllPlayersData();
  }, [selectedLeague]);

  const fetchAllPlayersData = async () => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 30); // Get data for the last 30 days

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    try {
      const response = await getGamesPlayedByDateRange(
        formattedStartDate,
        formattedEndDate
      );
      if (response.data && Array.isArray(response.data.data)) {
        const filteredData = response.data.data.filter(
          (game) => game.league === selectedLeague
        );
        const enhancedData = await Promise.all(
          filteredData.map(async (game) => {
            const userData = await getUserById(game.userId);
            return {
              ...game,
              co: userData.data.country || "-",
              state: userData.data.state || "-",
              city: userData.data.city || "-",
              player: userData.data.leagues[0]?.username || "-",
              BR:
                game.result?.perfectScore != null
                  ? parseFloat(game.result?.perfectScore).toFixed(2)
                  : "-",
              vegasOdds: game.result?.vegasOdds || {},
            };
          })
        );
        setAllPlayersData(enhancedData);
        const userStats = calculateUserStats(enhancedData);
        setUserStatsMap(userStats);
      }
    } catch (error) {
      console.error("Error fetching all players data:", error);
    }
  };

  // Calculate TP points and ranks
  const calculateTPandRank = (games) => {
    const tpValues = games.map((row) => {
      return parseFloat(
        (row.result?.accuracyPoints?.home?.p1s || 0) +
          (row.result?.accuracyPoints?.vistor?.p1s || 0) +
          (row.result?.accuracyPoints?.home?.p1s2p || 0) +
          (row.result?.accuracyPoints?.vistor?.p1s2p || 0) +
          (row.result?.accuracyPoints?.home?.p2s2p || 0) +
          (row.result?.accuracyPoints?.vistor?.p2s2p || 0) +
          (row.result?.vegasOdds?.pickingFavorite ||
            row.result?.vegasOdds?.pickingUnderdog ||
            0) +
          (row.result?.vegasOdds?.pickingOver ||
            row.result?.vegasOdds?.pickingUnder ||
            0) +
          (row.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
            row.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
            0) +
          (row.BR || 0)
      ).toFixed(2);
    });

    // Rank the TP values
    const sortedGames = tpValues.sort((a, b) => b.tp - a.tp);
    const ranks = sortedGames.map((game, index) => index + 1);

    return { tpValues, ranks };
  };

  // Calculate TP points and ranks
  const { tpValues, ranks } = calculateTPandRank(allPlayersData);

  const aggregatePlayerData = (data) => {
    const playerMap = {};

    data.forEach((game) => {
      if (!playerMap[game.userId]) {
        playerMap[game.userId] = {
          co: game.co,
          state: game.state,
          city: game.city,
          player: game.player,
          userId: game.userId,
          gamesPlayed: 0,
          totalBR: 0,
          uniqueNights: new Set(), // To track unique nights
          wins: 0,
          losses: 0,
          oneS: 0,
          oneS0: 0,
          oneSW2: 0,
          twoSW2: 0,
          ml: 0,
          spread: 0,
          ou: 0,
          games: [],
        };
      }

      const player = playerMap[game.userId];
      player.gamesPlayed++;
      player.totalBR += parseFloat(game.BR) || 0;
      player.uniqueNights.add(
        game.date
          ? game.date.split("T")[0]
          : `game-${game.id || player.gamesPlayed}`
      ); // Add the date (without time) to the set
      player.wins += game.BR > 0 ? 1 : 0;
      player.losses += game.BR <= 0 ? 1 : 0;

      player.oneS +=
        (game.result?.accuracyPoints?.home?.p1s || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s || 0);

      player.oneS0 +=
        (game.result?.accuracyPoints?.home?.p1s0 || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s0 || 0);

      player.oneSW2 +=
        (game.result?.accuracyPoints?.home?.p1s2p || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s2p || 0);

      player.twoSW2 +=
        (game.result?.accuracyPoints?.home?.p2s2p || 0) +
        (game.result?.accuracyPoints?.vistor?.p2s2p || 0);

      player.ml += parseFloat(
        game.result?.vegasOdds?.pickingFavorite ||
          game.result?.vegasOdds?.pickingUnderdog ||
          0
      );
      player.spread += parseFloat(
        game.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
          game.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
          0
      );
      player.ou += parseFloat(
        game.result?.vegasOdds?.pickingOver ||
          game.result?.vegasOdds?.pickingUnder ||
          0
      );
      player.games.push(game);
    });

    return Object.values(playerMap);
  };

  const aggregatedPlayersData = aggregatePlayerData(allPlayersData);

  // Calculate TP points and ranks for aggregated data
  const { tpValues: aggregatedTPValues, ranks: aggregatedRanks } =
    calculateTPandRank(aggregatedPlayersData);

  const renderNHLColumns = (player) => {
    // Calculate total Regulation, Overtime, and Shootout points
    const totalReg = player.games.reduce(
      (sum, game) => sum + calculateReg(game),
      0
    );
    const totalOT = player.games.reduce(
      (sum, game) => sum + calculateOT(game),
      0
    );
    const totalSO = player.games.reduce(
      (sum, game) => sum + calculateSO(game),
      0
    );

    return (
      <>
        <td className="text-xs font-medium text-center">{player.co || "-"}</td>
        <td className="text-xs font-medium text-center">
          {player.state || "-"}
        </td>
        <td className="text-xs font-medium text-center">
          {player.city || "-"}
        </td>
        <td
          className="text-xs font-medium text-center"
          style={{ color: "#dba418", fontSize: "17px" }}
        >
          {player.player || "-"}
        </td>
        <td className="text-xs font-medium text-center">
          {player.rank || "-"}
        </td>
        <td className="text-xs font-medium text-center">
          {((player.wins / player.gamesPlayed) * 100).toFixed(2)}%
        </td>
        <td className="text-xs font-medium text-center">
          {player.gamesPlayed}
        </td>
        <td className="text-xs font-medium text-center">
          {player.totalBR.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">{player.wins}</td>
        <td className="text-xs font-medium text-center">{player.losses}</td>
        <td className="text-xs font-medium text-center">
          {calculateCurrentStreak(player.games)}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateWinStreak(player.games)}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateLossStreak(player.games)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.oneS.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.oneS0.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.ml.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.spread.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.ou.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.gamesPlayed > 0
            ? (player.totalBR / player.gamesPlayed).toFixed(2)
            : "0.00"}
        </td>
        <td className="text-xs font-medium text-center">
          {player.uniqueNights.size > 0
            ? (player.totalBR / player.uniqueNights.size).toFixed(2)
            : "0.00"}
        </td>
        <td className="text-xs font-medium text-center">
          {calculatePointsPercentage(player.games, "favoritePoints")}
        </td>
        <td className="text-xs font-medium text-center">
          {calculatePointsPercentage(player.games, "underdogPoints")}
        </td>
        <td className="text-xs font-medium text-center">{totalReg || "0"}</td>
        <td className="text-xs font-medium text-center">{totalSO || "0"}</td>
        <td className="text-xs font-medium text-center">{totalOT || "0"}</td>
        <td className="text-xs font-medium text-center">
          {calculateL10(player.games)}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateFavoritePointsPercentage(player.games)}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateUnderdogPointsPercentage(player.games)}
        </td>
      </>
    );
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {headerOptions.NHL.map((item, ind) => (
              <th
                key={ind}
                className="text-xs font-medium"
                style={{
                  color: "#dba418",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <div style={{ height: "15px" }}></div>
        <tbody>
          {aggregatedPlayersData.length > 0 ? (
            aggregatedPlayersData.map((player, index) => (
              <tr
                key={index}
                className="h-14 bg-[#181818] text-white row-slide-in"
                style={{
                  marginTop: "-30px",
                  animationDelay: `${index * 0.05}s`,
                }}
              >
                {renderNHLColumns(player)}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={headerOptions.NHL.length}
                className="text-xs font-medium text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NHLStandingTables;
