import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import resultHero from "../assets/bgpic.png"; // Default image
import HeroSection from "../components/HeroSection";
import MainNavBar from "../components/MainNavBar";
import { Box, Typography } from "@mui/material";
import LeagueSelect from "../components/LeagueSelect";

const MainLayout = () => {
  const location = useLocation();
  const shouldHideFooter = () => {
    return location.pathname === "/admin";
  };

  // State to manage the current hero image
  const [heroImage, setHeroImage] = useState(resultHero); // Set default image

  const handleLeagueChange = (leagueImage) => {
    setHeroImage(leagueImage);
  };

  const pathName = window.location.pathname;
  const showSearchBar =
    pathName === "/results" ||
    pathName === "/standings" ||
    pathName === "/stats";

  return (
    <>
      <LeagueSelect onLeagueChange={handleLeagueChange} />
      <br />
      {/* <HeroSection imgUrl={heroImage} alt="Hero Section" /> Use dynamic image */}
      <br />
      <MainNavBar />
      {location.pathname !== "/admin" && (
        <Box
          sx={{
            height: "30px",
            borderRadius: "25px",
            margin: "20px 20px",
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              // color: "white",
              whiteSpace: "nowrap",
              position: "relative",
              animation: "marquee 10s linear infinite",
            }}
          >
            <marquee
              className="marq"
              direction="left"
              loop=""
              style={{
                width: "100%",
                position: "absolute",
                left: "0",
                color: "white",
                backgroundColor: "red",
              }}
            >
              Results are updated hourly until we find a better solution
            </marquee>
          </Typography>
        </Box>
      )}
      {/* {showsearchBar && <SearchBar />} */}

      <div className="flex justify-content">
        <div className="w-full ml-5 mt-4">
          <Outlet />
        </div>
      </div>
      {!shouldHideFooter() && <Footer />}
    </>
  );
};

export default MainLayout;
